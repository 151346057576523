/*================ Global | Sass Mixins ================*/
@mixin clearfix() {
  &:after, &:before {
    content: "";
    display: table;
    clear: both; }
  *zoom: 1;
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin transition($transition: 0.1s all) {
  @include prefix('transition', #{$transition});
}

@mixin transform($transform: 0.1s all) {
  @include prefix('transform', #{$transform});
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation:    $animation;
  -o-animation:      $animation;
  animation:         $animation;
}

@mixin gradient($from, $to, $fallback) {
  background: $fallback;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background: -o-linear-gradient(top, $from 0%, $to 100%);
  background: -ms-linear-gradient(top, $from 0%, $to 100%);
  background: linear-gradient(top bottom, $from 0%, $to 100%);
}

@mixin backface($visibility: hidden) {
  @include prefix('backface-visibility', #{$visibility});
}

@mixin visuallyHidden {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

@mixin desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin box-sizing($box-sizing: border-box) {
  -webkit-box-sizing: #{$box-sizing};
  -moz-box-sizing: #{$box-sizing};
  box-sizing: #{$box-sizing};
}

@function em($target, $context: $baseFontSize) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

@function color-control($color) {
  @if (lightness( $color ) > 50) {
    @return #000;
  }
  @else {
    @return #fff;
  }
}

@function menu-grid($totalWidth, $curCol, $totalCol) {
  @return $curCol * $totalWidth / $totalCol + 0px;
}

/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
$min: min-width;
$max: max-width;
@mixin at-query ($constraint, $viewport1, $viewport2:null) {
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/*============================================================================
  THEME MIXINS
==============================================================================*/

/*HOVER SCREEN*/
@mixin hover-screen() {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.20);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5,0.5);
  -ms-transform: scale(0.5,0.5);
  -o-transform: scale(0.5,0.5);
  transform: scale(0.5,0.5);
  opacity: 0;

}
@mixin hover-screen-show() {
  -webkit-transform: scale(1,1);
  -ms-transform: scale(1,1);
  -o-transform: scale(1,1);
  transform: scale(1,1);
  opacity: 0.5;
  visibility: visible;
}

/*QUICK VIEW*/
@mixin general-quick-view() {
  z-index: 20;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%); 
  span {
    font-size: 14px;
    color: #fff;
    visibility: hidden;
    width: auto;
    height: 30px;
    display: block;
    padding: 0 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
    line-height: 30px;
    -webkit-backface-visibility: visible !important;
      backface-visibility: visible !important;

    &:before {
      //content: "\f002";
      display: block;
      font-family: "FontAweSome";
      font-size: 15px;
      color: inherit;           
    }

    @media (max-width: 991px) {
      //width: 30px;
      height: 30px;
      line-height: 30px;    
    }
    @media (max-width: 767px){
      display: none;
      //width: 25px;
      height: 25px;
      line-height: 25px;
      &:before {
        font-size: 10px;
      }
    }
  }
}

@mixin general-quick-view-show() {
  span {
    visibility: visible!important;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown; 
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
}

/*============================================================================
  Accent text
==============================================================================*/

@mixin fontStyle_1() {
  font-size: em(10px);
  font-family: $fontHeading;
}

@mixin fontStyle_2() {
  font-size: em(12px);
  font-family: $fontHeading;
}

@mixin fontStyle_3() {
  font-size: em(14px);
  font-family: $fontHeading;
}

@mixin fontStyle_4() {
  font-size: em(12px);
  font-family: $fontText;
}

@mixin fontStyle_5() {
  font-size: em(16px);
  font-family: $fontHeading;
}

@mixin fontStyle_6() {
  font-size: em(18px);
  font-family: $fontHeading;
}

@mixin fontStyle_7() {
  font-size: em(16px);
  font-family: $fontHeading;
}
@mixin imghover() {
  &:before, &:after {
        left: 0;
            top: 0;
            position: absolute;
            display: block;
            width: 0%;
            height: 0%;
            background: #fff;
            opacity: 0.1;
            content: '';
            -moz-transition: 0.5s all ease;
            -o-transition: 0.5s all ease;
            -webkit-transition: 0.5s all ease;
            transition: 0.5s all ease;
            z-index: 1;
      }
      &:before{
        left: 100%;
        top: 100%;
      }
      &:hover{
        &:before, &:after {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
}
@mixin fade-button() {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown; 
          -webkit-animation-duration: .5s;
          animation-duration: .5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
          display: none;
          margin: 0;
          width: 40px;
          height: 40px;
}
@mixin blog-icon($content){
  font-size: 0;   
    display: inline-block;
    text-align: center;    
    font-weight: normal;
    padding: 0 5px 0 0;
    margin: 0 !important;    
    &:before{
      content: $content;
      font-family: $fonticont;
      font-size: 13px;
      display: inline-block;
      color: $colorText2;
      font-weight: normal;      
    } 
}
@mixin preload() {
  position: fixed;
  width: 12px;
  height: 12px;
  left: 46%;
  top: 50%;
 width: 49px;
  height: 49px;
  border-radius: 50%;
  margin: 3em;
  display: inline-block;
  vertical-align: middle;
   animation: 1.15s infinite ease-in-out;
    -o-animation: 1.15s infinite ease-in-out;
    -ms-animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
    &:before,&:after{
    
    width: 100%; 
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
    background-color: $colortheme1;
    transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    animation: cssload-animation 1.73s infinite ease-in-out;
    -o-animation: cssload-animation 1.73s infinite ease-in-out;
    -ms-animation: cssload-animation 1.73s infinite ease-in-out;
    -webkit-animation: cssload-animation 1.73s infinite ease-in-out;
    -moz-animation: cssload-animation 1.73s infinite ease-in-out;
    }
    &:after{
    animation-delay: 0.86s;
    -o-animation-delay: 0.86s;
    -ms-animation-delay: 0.86s;
    -webkit-animation-delay: 0.86s;
    -moz-animation-delay: 0.86s;
    }
    
}
@keyframes cssload-animation {
  0%   { transform: translateX(-100%) scale(0); }
  50% { transform: translateX(0%)   scale(1); }
  100% { transform: translateX(100%)  scale(0); }
}

@-o-keyframes cssload-animation {
  0%   { -o-transform: translateX(-100%) scale(0); }
  50% { -o-transform: translateX(0%)    scale(1); }
  100% { -o-transform: translateX(100%) scale(0); }
}

@-ms-keyframes cssload-animation {
  0%   { -ms-transform: translateX(-100%) scale(0); }
  50% { -ms-transform: translateX(0%)   scale(1); }
  100% { -ms-transform: translateX(100%)  scale(0); }
}

@-webkit-keyframes cssload-animation {
  0%   { -webkit-transform: translateX(-100%) scale(0); }
  50% { -webkit-transform: translateX(0%)   scale(1); }
  100% { -webkit-transform: translateX(100%)  scale(0); }
}

@-moz-keyframes cssload-animation {
  0%   { -moz-transform: translateX(-100%) scale(0); }
  50% { -moz-transform: translateX(0%)    scale(1); }
  100% { -moz-transform: translateX(100%) scale(0); }
}
