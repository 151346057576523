/*=========================================== CSS FOR HEADER ===========================================================*/
.header-container {
	@media (max-width: 767px) {
		padding-bottom: 69px;
	}
}
header {

	.container {
		overflow: visible;
	}

	.nav {
		background: none;
		border-bottom: 1px solid #f1f1f1;
		color:$colorText2;
		.row {
			@media (max-width: 767px) {
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		nav {
			min-height: 38px;

			@media (max-width: 767px) {
				padding-left: 15px;
				padding-right: 15px;
				text-align: center;
			}
		}
		.contact-header{
			display: inline-block;
			line-height: 38px;
			font-size: 14px;
			span{
				padding-right: 30px;
				i{
					padding-right: 10px;
				}
			}
			@media (max-width: 767px) {
				font-size: 12px;
				display: none;
				span{
					padding-right: 10px;
				}
			}
		}
		#languages-block-top, #currencies-block-top,.header_links {			
			border: none;
			border-right: 1px solid #eeeeee;
			max-height: 100%;
			@media (max-width: 767px) {
				padding: 0px;
				border: none;
				float: none;
				display: inline-block;
			}
			ul{
				z-index: 100;
			}
			.current {
				line-height: 38px;
				padding: 0 10px;
				color: $colorText2;
				cursor: pointer;
				@media (max-width: 767px) {
					padding-left: 10px;
					padding-right: 10px;
				}

				&::after {
					content: "\f107";					
					font-size: 14px;
					padding-left: 3px;
					vertical-align: 0px;
					text-shadow: none;
					font-family: $fonticont;
				}

				span, strong {					
					text-shadow: none;					
					font-weight: normal;
					font-size: 14px;
				}

				&:hover, &.active {
					background: none;
					&:after, span, strong {
						color: $colortheme1;						
					}
				}
				@media (max-width: 767px) {
					span, strong {		
						font-size: 12px
					}
					&::after {
						font-size: 12px;
					}
				}
			}

			ul {
				li{
					&.selected, &:hover {
						background: $bkgMenu;
						a {
							background: $bkgMenu;
						}
					}
				}
				@media (max-width: 767px) {
					right: 0;
					left: auto;
					z-index: 101;
				}
			}
		}

		.social-header {

			@media (max-width: 991px) {
				width: auto;
			}
			@media (max-width: 767px) {
				float: right;
				padding-right: 0;
			}

			ul {

				li {
					float: left;
					font-family: "FontAwesome";
					color: #333;
					cursor: pointer;
					-webkit-transition: all .5s;
					-o-transition: all .5s;
					transition: all .5s;
					@media (max-width: 767px) {
						padding-right: 0;
						padding-left: 8px;
					}

					a {
						font-size: 0;
						padding-top: 9px;
						padding-right: 15px;
						display: inline-block;
						&:hover {
							color: $bkgMenu;
						}
						@media (max-width: 767px) {
							padding-right: 8px;
						}
					}

					&.facebook {

						a:before {
							content: "\f09a";
							font-size: 10px;						
						}
					}

					&.twitter {

						a:before {
							content: "\f099";
							font-size: 10px;							
						}
					}

					&.google-plus {

						a:before {
							content: "\f0d5";	
							font-size: 10px;						
						}
					}

					&.dribbble {

						a:before {
							content: "\f17d";	
							font-size: 10px;						
						}
					}

					&.linkedin {

						a:before {
							content: "\f0e1";	
							font-size: 10px;						
						}
					}
				}
			}
		}

		.header_user_info {
			border: none;
			padding-left: 20px;
			padding-top: 10px;
			font-size: 10px;
			display: none;
		
			@media (max-width: 767px) {
				@include clearfix();
				clear: both;
				float: none;
				padding: 0 15px;
				text-align: center;
				display: none;
				span, a {
					float: none!important;
					display: inline-block;
				}
			}

			span {
				float: left;
				padding-right: 5px;
			}

			a {
				padding: 0;
				color: $colorText;
				float: left;
				&:hover {
					background: none;
					color: $bkgMenu;
				}
			}
		}

		.header_links {
			@include clearfix();
			display: inline-block;
			float: right;			
			position: relative;
			.dropdown-toggle {
				display: none;
			}

			#header_links {		
				display: none; 		
				position: absolute;
				top: 37px;
				left: 0;
				margin-top: 0;
				box-shadow: none;
				border: none;
				background-color: $colortheme;				
				width: 157px;
				z-index: 100;
				li {
					border-left: none;
					color: $colorText1; 
					float: none;
					display: block;
					line-height: 35px;					
					@media (max-width: 991px) {
						padding-left: 15px;
					}
					

					a {
						padding: 0 10px;

						&:hover {
							background-color: transparent;
							color: $colorText1;
							text-decoration: none;
						}
					}

					.icon {
						margin-right: 5px;
					}
				}
			}

			@media (max-width: 767px) {				
				margin: 0 auto;
				display: inline-block;
				float: none;

				ul#header_links {
					float: none;
					left: auto;
					text-align: left;
				}
			}	
		}
	}
	.shopping_cart {  
			@include clearfix();
			padding-top: 0;
			@include fontStyle_2();				
			z-index: 10;
			text-align: right;
			width: auto;
			height: 50px;
			border-radius: 50px;
			border: 1px solid $colortheme3;
			padding: 0 20px;
			line-height: 50px;
			> a:first-child {
				background: transparent;
				text-shadow: none;
				color: $bkgMenuHover;
				padding: 0;				
				&:before {					
					background: url("../images/cart-icon.png") no-repeat center;
					color: $colorText;
					width: 24px;
					height: 24px;
					line-height: 24px;					
					padding-right: 0;				
					font-size: 13.5px;
					margin-right: 20px;					
					float: left;
					content: "";
					margin-top: 10px;
					font-family: $fontText;
					@media (max-width: 767px) {
						margin-right: 0;
						margin-bottom: 0;
						top: 0;
					}
				}

				&:after {
					content: "\f107";
					float: none;
					font-size: 11px;
					margin-left: 5px;
					display: none !important;
				}

				&:hover:after {
					content: "\f106";
				}
				@media (max-width: 767px) {
					padding: 0;
				}
				.ajax_cart_quantity {
					display: block !important;
					width: 20px;
					height: 20px;
					position: absolute;					
					border-radius: 50%;
					top: 5px;
					left: 35px;
					background: $colortheme1;
					color: $colorText1;
					text-align: center;
					padding-right: 0 !important;					
					line-height: 20px;
					font-weight: bold;
					font-size: 14px;
					@media (max-width: 767px) {
						right: -4px;
						left: auto;
						top: 0px;
					}
				}

				b,span {
					//float: left;
					font-weight: normal!important;
					color: $colorText;
					font-size: 16px;
					@include fontStyle_7();
				}

				.ajax_cart_product_txt,
				.ajax_cart_product_txt_s {
					display: none !important;
				}

				.ajax_cart_no_product {
					min-width: 50%; 
					@media (max-width: 767px) {
						display: none !important;
					}
				}

				.ajax_cart_total {
					//float: left;
					//min-width: 50%;
					@media (max-width: 767px) {
						display: none !important;
					}
				}

				b {					
					color: $colorText;
					text-shadow: none;					
					//float: left;
					width: 50%;
					padding-left: 10px;
					border-left: 1px solid $colortheme3;
					@media(max-width: 767px) {
						display: none !important;

					}
				}
			}
			@media(max-width: 767px) {
				border: none;
				padding: 0 10px 0 0;
			}
		}
	.header-middle {
		background: #fff;
		padding-top: 40px;	
		padding-bottom: 40px;
		border-bottom: 1px solid $colortheme3;
		@media (max-width: 767px) {
			padding-top: 20px;
			padding-bottom: 20px;
			.contact-header{
				display: none;
			}
			.col-sm-5{
				width: calc(100% - 112px);
				position: absolute;
				#search_block_top{
					width: 100%;
				}
			}
		}

		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
		.contact-header{
			.icon-mailicon{
				float: left;
				margin-right: 15px;
				img{
					vertical-align: middle;
				}
			}
			span{
				display: block;
				font-weight: bold;				
				&:first-child{
					margin-bottom: 5px;
				}
			}
		}
		#header_logo {
			padding-left: 0;
			padding-top: 0;	
			z-index: 10;		
			.img-responsive{
				display: inline-block;
			}
			@media (min-width: 1200px) {
				
			}
			@media (max-width: 1199px) and (min-width: 992px) {
				
			}
			@media (max-width: 991px) {
				padding: 0 15px;				
				
				img {
					max-width: 200px;
				}

				>a {
					display: inline-block;
				}
			}
			@media (max-width: 767px) {
				padding: 10px 15px 40px;
				text-align: center;
			}
		}
		
		#search_block_top {
			padding-top: 0;
			padding-left: 50px;
			position: relative;
			z-index: 100;

			form {
				position: relative;
		
			}

			@media(max-width: 991px) {
				//width: 58.33333%;
				padding-left: 15px;
			}
			@media(max-width: 767px) {
				width: calc(100% - 130px);
				padding: 0;
				clear: both;
				display: inline-block;
				margin-left: 60px;
			}

			.search_query {
				width: 100%;
				height: 50px; 
				padding: 0 73px 0 20px; 
				border-radius: 50px;
				background: none !important;
				@media (max-width: 767px) {
					height: 40px;
					padding-right: 63px;
				}
			}

			.button-search {
				width: 45px;
				height: 45px;
				min-width: 0;
				position: absolute;
				background: none;
				right: 5px;
				top: 2px;
				border: none;
				padding: 0;
				color: $colorText2;
				@media (max-width: 767px) {
					height: 38px;
					width: 38px;
					right: 0;
				}

				&:before {
					font-size: 16px;
				}
				&:hover {
					color: #fff;
					background: $colortheme1;
				}
			}

			#show_result {
				position: absolute;
				background: $bkgMenuHover;
				width: 100%;
				left: 0;
				top: 100%;
				z-index: 100;
				color: #fff;
				@media (max-width: 767px) {
					width: calc(100vw - 30px);
					margin-left: -50px;
				}
				.result {
					width: 100%;
					padding: 15px;
				}
				#closedform {
					position: absolute;
					right: 0px;
					top: 0px;
					width: 30px;
					height: 30px;
					text-align: center;
					line-height: 30px;
					cursor: pointer;
					font-size: 14px;
					font-family: $fontHeading;
					&:hover {
						color: $bkgMenu;
					}
				}
				h1 {
					@include fontStyle_6();
					text-transform: uppercase;
					margin: 0;
					padding: 0 0 20px;
				}
				#search-products_block {
					li {
						border-bottom: 1px dotted grey;
						.products-block-image {
							float: left;
							margin-right: 10px;
						}
						h5 {
							margin-bottom: 0;
						}
						.product-name {
							color: #fff;
						}
						.price-box{
							.price {
								color: $bkgMenu;
								font-size: em(14px);
								font-family: $fontHeading;
							}
						}
						&:last-child {
							border: none;
							margin-bottom: 0;
							padding-bottom: 0;
						}
					}	
				}
			}
		}

		@media (max-width: 991px) {
			.col-sm-3 {
				
			}
		}

		@media (max-width: 767px) {
			.col-sm-3 {
						

			}
		}

		
	}

	/*cart block*/
	&#header {
		.cart_block {
			top: 100%;
			width: 290px;
			background: #fff;
			border: 1px solid #eee;
			color: $bkgMenuHover;
			-webkit-box-shadow: 0 5px 10px rgba(238,238,238,0.4);
			box-shadow: 0 5px 10px rgba(238,238,238,0.4);
			.products {
				padding: 20px 20px 0;
				margin: 0;
			}
			.cart_block_list {
				.remove_link {
					right: 0;
					top: 0;
					.ajax_cart_block_remove_link{
						color: $bkgMenuHover;
						width: 20px;
						height: 20px;
						text-align: right;
						&:hover{
							color: $bkgMenu;
						}
						&:before{
							content:"\f1f8";
							font-size: 13px;
						}
					}	
				}
			}
			dt{
				-webkit-box-shadow: none;
				box-shadow: none;
				background: none;
				padding: 0;
				border-bottom: 1px solid #eee;
				padding-bottom: 0;
				margin-bottom: 20px;
				&.last_item {
					border: none;
					margin-bottom: 0;
				}
			}
			.cart-prices {
				background: none;
			}
			.cart-buttons {
				background: none;
			}
			.cart-info {
				.product-name {
					.cart_block_product_name {
						color: $bkgMenuHover;
						a {

						}
					}
					.quantity-formated {
						.quantity{
							font-size: em(12px);	
						}
					}	
				}
			}
			.product-atributes {
				a {
					color: $bkgMenuHover;
				}	
			}
			.cart-prices {
				.cart-prices-line {
					border-color: #eee;
				}	
			}
			.price {
				color: $bkgMenu;	
				font-size: em(14px);
			}
			.cart-buttons {
				padding-top: 0;
				#button_order_cart {
					@extend .btn-style-3;					
					padding: 0;
					span {
						border: none;
						background: none;
						padding: 0;
					}
				}	
			}
			@media (max-width: 767px) {
				top: 40px;
			}
		}
		.banner {
			background: $colorText;
			a {
				max-width: 100%;
				img {
					max-width: 100%;
				}
			}
		}
	} 
}