/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
	@include clearfix();	

	ul {
		margin: 0;

		li {
			padding: 48px 43px;
			color: #fff;
			@media (max-width: 1199px) {
				padding: 43px 40px;
			}

			&:nth-child(odd) {
				background: $bkgMenu;
			}

			&:nth-child(even) {
				background: $colortheme4;
			}

			.icon {
				font-size: em(24px);
				float: left;
				margin-right: 20px;
				margin-top: 5px;
			}

			h4 {
				text-transform: uppercase;
				margin-bottom: 8px;
			}

			p {
				font-family: $fontTextArt;
				font-style: italic;
				color: $colorText1;
			}

			@media (max-width:1199px) {
				padding: 38px 30px;
				white-space: nowrap;
				h4 {
					margin-bottom: 5px;
				}
			}

			@media (max-width: 991px) {
				padding: 20px 15px;
				white-space: nowrap;
				h4 {
					margin-bottom: 3px;
				}
				.icon {
					margin-right: 10px;
				}
			}
		}
	}
	background: $colortheme1;
	text-align: center;
	padding: 80px 0;
	color: $colorText1;
	#newsletter_block_left{
		margin: 0;
		.news_content{
			width: 42%;	
			margin: 0 auto;	
			@media (min-width: 767px){
				width: 60%;
			}
			@media (min-width: 991px){
				width: 42%;
			}
			@media (max-width: 767px){
				width: 100%;
			}	
				h4{
					background: none;
					font-size: 24px;
					border:none;
					color: $colorText1;
					margin: 0;
					padding: 0 0 30px;
				}
				.block_content{
					margin: 30px 0 0;
					.form-group{
						position: relative;
						margin: 0;
						input[type="text"]{
							@extend .btn-style-2;
							background: $colortheme2;
							color: $colorText2;
							font-size: 16px;
							text-align: left;
							text-transform: none;
							padding: 0 30px;
							&:hover{
								background: $colortheme2;
								color: $colorText2;
							}
							&:focus{
								border-color:#494276;
								box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(84, 77, 132);
							}
						}
						.button{
							position: absolute;
							top: 5px;
							right: 10px;
							@extend .btn-style-3;
							width: 40px;
							font-size: 0;
							text-align: center;
							min-width: 0;
							padding: 0;
							span{
								display: inline-block;
								width: 40px;
								line-height: 40px;
								text-align: center;
								&:before{
									font-family: $fonticont;
									content: "\f1d8";
									font-size: 18px;

								}
							}

						}
					}
				}
		}
	}
}

.footer-container {
	background: none;
	background-color: $colorText;
	color: $colorText2;
	padding: 80px 0;

	@media (max-width: 767px) {
		padding-top: 30px;
	}

	.container {
		padding: 0;
		@media (max-width: 767px) {
			
		}
	}

	#footer {
		.product-name a{
			color: $colorText1;
			font-size: 14px;
		}
		h4{
			color: $colorText1;
			margin: 0 0 40px;
			padding: 0 0 20px;
			position: relative;
			&:before{
				content: "";
				width: 70px;
				height: 1px;
				background: #393939;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			@media (max-width: 767px){
				margin: 0;
			}
		}
		.footer_product{
			margin-top: 30px;
		}
		ul{
			margin: 0;
			padding: 0;
			li{
				a{
					line-height: 35px;
					font-size: 14px !important;
					padding: 0 !important;
					text-transform: none !important;
					&:hover{
						color: $colorText1;
					}
				}
			}
		}
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}	
	}

	.footer-line {
		// height: 1px;
		// background: #ededed;	
		// width: calc(100% - 30px);
		// margin: 40px auto 40px;
	}

	#newsletter_block_left {
		width: 75%;
		display: inline-block;
		float: left;

		.news_logo {
			width: 33.333333%;
			float: left; 
			padding-right: 15px;
			padding-top: 55px;

			@media (max-width: 767px) {
				width: 100%;
				padding: 0;
				text-align: center;
				padding-bottom: 20px;				
			}
		}

		.news_content {
			width: 66.666666%;
			float: left;
			background: #f2f4f3;
			padding: 47px 33px;

			@media (max-width: 991px) {
				padding: 30px 20px; 
			}
			@media (max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
			}

			h4 {
				@include fontStyle_2();
				color: $colorHeading; 
				text-transform: uppercase;
				line-height: 1.4;
				width: 21%;
				padding: 0;
				padding-right: 10px;
				margin: -3px 0 0;
				span {
					font-family: $fontTextArt;
					font-size: em(20px);	
					color: $colorHeading;
					font-style: italic;
					text-transform: none;
				}
				@media (max-width: 991px) {
					width: 100%;
					span {
						display: block;
					}
				}
				@media (max-width: 767px) {
					width: 100%;
					padding-bottom: 15px;
					text-align: center;
					span {
						display: block;
					}
				}
			}

			.block_content {
				@media (max-width: 991px) {
					clear: both;
					margin-bottom: 0;
				}	
			}

			.form-group {
				.button-small {
					color: #fff;
					@include fontStyle_1();
					min-width: 0;
					//padding: 0 20px !important;
				}
			}
		}

	}

	#social_block {
		width: 25%;
		float: left !important;
		clear: both;
		padding: 0 !important;
		ul {
			float: left !important;
			@include clearfix();
			@media (max-width: 767px) {
				text-align: center;
			}

		}

		h4 {
			display: none;
		}

		li {
			width: 30px;
			height: 30px;			
			margin-left: 20px;
			border-radius: 30px;
			border: 1px solid $bordercolor;
			@media (max-width: 1199px) {
				margin-left: 15px;
			} 
			@media (max-width: 991px) {
				
			}  
			@media (max-width: 767px) {
				
			} 

			&:hover {
				background: $bkgMenu;  
				color: #fff; 
				border-color: $bkgMenu;;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				color: #aaa;
				padding-top: 6px;
				line-height: 20px !important;
				&:before{
					vertical-align: 0;
				}
				@media (max-width: 991px) {
					padding-top: 2px;
				}
				@media (max-width: 767px) {
					padding-top: 6px;
				}

				&:hover {
					color: #fff;
				}
			}
		}
		@media (max-width: 767px) {
			margin-top: 20px;
		}
	} 

	.footer-block {
		.comments_note{
			text-align: left;
			.star_content .star:after{
				color: $colortheme1 !important;
			}
		}

		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px; 
		}

		.links_footer_left, .links_footer_right {
			width: 50%;
			float: left;

			li {
				&:last-child a {
					padding-bottom: 0;
				}
				a {
					font-weight: normal;
					&:hover {
						color: $bkgMenu;
					}	
				}
			}
		}

		&#block_various_links_footer {
			a {
				text-transform: uppercase;
				font-size: em(11px);
				padding-bottom: 17px;
				display: block;
			}
		}
		
	}

	.bc-filterproduct {
		@media (max-width: 767px) {
			clear: both;
		}
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		.product-container {
			@include clearfix();
			margin-bottom: 22px;

			.left-block {
				width: 70px;
				float: left;
				margin-right: 20px;	
				position: relative;

				.product_img_link:before {
					@include hover-screen();
					background: $colortheme5;				
				}

				img {
					width: 100%;
				}
				&:hover{
					
				}
			}

			.right-block {
				width: calc(100% - 90px);
				float: left; 
			}

			.product-name {
				text-align: left;
				margin-top: 0;
				margin-bottom: 5px;
			}	

			.content_price {

				.product-price	{
					color: $bkgMenu;
				}
			}

			.stock-availability {
				display: none;
			}

			.functional-buttons {
				.quick-view-wrapper-mobile {
					display: none;
				}
				.quick-view {
					@include general-quick-view();					
					span {
						width: 20px;
						height: 20px;
						line-height: 20px;
						font-size: 0;
						background: none;
						box-shadow: none;
						padding: 0;
						&:before {							
							content: "\f002";
						}
					}
				}	
			}
			&:hover {
				.left-block {
					.product_img_link:before {
						@include hover-screen-show();
					}	
				}
				.functional-buttons {
					.quick-view {
						@include general-quick-view-show();
					}	
				}	
			}
		}	
	}

	.bc_tags_block {
		h4 {
			text-transform: uppercase;
			color: $colorHeading;
			@include fontStyle_6();
			margin-bottom: 42px;
		}

		a {
			text-transform: uppercase;
			font-size: em(11px);	
			border: 1px solid #ededed;
			padding: 0 18px;
			float: left;
			margin-right: 10px;
			margin-bottom: 12px;
			min-height: 30px;
			padding-top: 5px;
			@media (max-width: 991px) {
				padding-right: 5px;
				padding-left: 10px;
				padding-right: 10px;
			}

			&:hover {
				background: $colortheme;
				color: #fff;
			}
		}
	}

	#block_contact_infos {
		.news_logo{
			margin: 0 0 50px;
		}
		li {
			padding-left: 0 !important;
			font-family: $fontText;
			color: $colorText2;
			font-size: em(14px);			
			span {				
				font-size: em(14px);
				font-family: $fontHeading;	
				color: $colorText1 !important; 
				padding-right: 5px;
				float: left;
			}
			i {
				display: none;
			}
			a{
				line-height: 0 !important;
			}
		}
		@media (max-width: 767px) {
			clear: both;
			
		}
		h4{
			//vndisplay: none;
			@media (max-width: 767px){
				
			}
		}
	}
}

.bc-bottom-footer {
	background: $colortheme7; 
	padding: 30px 0;
	color: $colorText2;
	.container {
		@media (max-width: 991px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.copy-right {
		text-transform: uppercase;
		@include fontStyle_2();
		color: #727272; 
		@media (max-width: 776px) {
			text-align: center;
		}
	}

	.bc-bewaer-ft {
		text-align: right;

		a {
			display: inline-block;
		}
		@media (max-width: 776px) {
			text-align: center;
		}
	}
}