/*=============================== GLOBAL CSS ======================================================*/
html,body {
	font-family: $fontText;
	font-size: $baseFontSize;
	color: $colorText;

	@media (max-width: 1199px) {
		font-size: $baseFontSize*0.9;
	}

	@media (max-width: 991px) {
		font-size: $baseFontSize*0.85;
	}
}

body {
	min-width: 0;
}

a {
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

img {
	max-width: 100%;
	height: auto;
}

.button,
button, 
button:focus, 
button:active,
button:visited {
	outline: none;
	@extend .btn-style-3;
	border: none;	
	padding: 0 15px;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	-webkit-appearance: none;
	span {
		border: none;
		padding: 0;
	}

	[class^="icon-"] {
		display: none;
	} 
	&:hover{
		background: $colorText;
	}
}

.form-group.form-error {
	input {
		background-position: 98% 50%;
	}
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
	background: #fff;
	border: 1px solid #eee; 
	height: 35px;
	line-height: 35px;
	font-family: $fontText;
	font-size: 13px;
	color: $colorHeading;
	-webkit-appearance: none;
	border-radius: 0;

	&:focus {
		outline: none;
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
	-moz-appearance:textfield;	 
}

textarea.form-control {
	border-color: #eee;
	font-family: $fontText;
	font-size: 13px;
	color: $colorHeading;
}

fieldset {
	min-width: 0;
}
/*============================================================================================================*/

/*================================================LAYOUT MODES===========================================*/
.wide-mode {
	.bc-h-boxed-mode,
	.bc-f-boxed-mode {
		max-width: 1200px;
		margin: 0 auto;
	}	
}
.boxed-mode {
	max-width: 1200px;
	margin: 0 auto;
	background: #fff;
}
/*============================================================================================================*/

/*================================================CUSTOME CONTAINER===========================================*/
.columns-container {
	padding-top: 30px;
	@media (max-width: 767px) {
		padding-top: 0;
	}
}

.container {
	// @media (max-width: 1199px) {
	// 	padding-left: 0;
	// 	padding-right: 0;
	// 	overflow: hidden;
	// }
	// @media (max-width: 767px) {
	// 	padding-left: 15px;
	// 	padding-right: 15px;
	// 	overflow: visible;
	// }
}
#columns {
	@media (max-width: 991px) {
		//padding-left: 15px;
		//padding-right: 15px;	
	}
	padding-bottom: 0;
}

.menu-opened {
	overflow: hidden;

	body.menu-opened {
		position: fixed;
		left: 0;
		top: 0;
	}
}
/*=============================================================================================================*/

/*=============================================MOBILE MENU OPEN================================================*/
.menu-opened #page {
	cursor: url(../images/close.png),pointer;
	position: relative;
	&:before {
		content:"";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0,0,0,0.5);
		z-index: 9999;
	}
}
/*==============================================================================================================*/

/*SELECTOR*/
div.select {
	@include clearfix();
}
div.selector {
	height: 35px;
	background: none;
	border: 1px solid #eee;
	
	> span {
		background: none;
		width: 100%!important;
		height: 33px;
		position: relative;
		line-height: 33px;
		color: $colorHeading;
		font-size: 13px;
		text-align: left;

		&:after {
			content: "\f107";
			font-family: "FontAweSome";
			width: 29px;
			height: 33px;
			text-align: center;
			border-left: 1px solid #eee;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	select {
		width: 100%;
		height: 33px;
	}

	+ span {
		margin-top: 2px;
	}
}

/*RADIO*/
div.radio {
	span {
		background: none;
		border: 1px solid #eee;
		border-radius: 50%;

		&.checked {
			position: relative;
			border-color: $bkgMenu;
			&:after {
				content:"";
				width: 5px;
				height: 5px;
				display: block;
				border-radius: 50%;
				background: $bkgMenu;
				position: absolute;
				left: 3px;
				top: 3px;
			}
		}
	}
}

/*CHECKER*/
div.checker {
	width: 10px;
	height: 10px;
	position: relative;
	min-height: 0;
	display: inline-block!important;

	+ label { 

		a {
			-webkit-transition-duration: 0s;
			-o-transition-duration: 0s;
			transition-duration: 0s;
		}

		&:hover {

			a {
				color: $bkgMenu;	
			}
		}
	}

	span {
		background: none;		
		width: 10px;
		height: 10px;
		position: relative;
		z-index: 1;
		top: 0;
		vertical-align: top;
		border: 1px solid #eee;

		&.checked {
			border-color: $bkgMenu;
			&:before {
				content: "";
				width: 4px;
				height: 4px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -2px;
				margin-top: -2px;
				background: $bkgMenu;
			}
		}
	}

	input {
		width: 10px;
		height: 10px;
		min-height: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	&:hover {
		span {
			border-color: $bkgMenu;
		}

		+ label {
			color: $bkgMenu;	
		}
	}
}

/*NEW LABEL*/
.new-box {
	width: em(55px);
	height: em(55px);
	border-radius: 50%;
	display: none;
	text-align: center;
	@include fontStyle_3();	
	color: #fff;
	line-height: em(55px);
	background: $bkgMenuHover;
	position: absolute;
	left: em(10px);
	top: em(15px);
	z-index: 20;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;

	&:hover {
		background: $bkgMenuHover;
		color: #fff;
	}

	@media (max-width: 991px) {
		left: 0px !important;
		top: 0px !important;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 12px;
	}
	@media (max-width: 767px) {
		left: 0px !important;
		top: 0px !important;
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 10px;
	}
}

/*PRODUCT NAME */
.product-name {
	text-align: left;
	font-size: 16px;	
	text-transform: none;
	color: $colorText;
	line-height: 1;
	&:hover{
		color: $bkgMenu !important;
	}
	a {		
		font-size: 16px;
		line-height: inherit; 
		display: block;
	}
}

/*PRODUCT PRICE*/
.price.product-price, .old-price.product-price {
	color: $bkgMenu;
	@include fontStyle_5();	
	line-height: 1;
	margin: 15px 5px 10px;	
	font-weight: normal;
}

.old-price.product-price {
	color: $colorText2;
	text-decoration: line-through;
}

/*PRODUCT STOCK STATUS*/
.stock-availability {
	display: block;
	color: $bkgMenu;
	font-weight: bold;
}

/*PRODUCT REDUCTION STATUS*/
.product-flags {
	.discount {
		color: $bkgMenu;
		font-weight: bold;
	}
}

/*PRICE REDUCE PERCEN*/
.price-percent-reduction {
	display: none;
}

/*PRODUCT PRICE PECENT REDUCE*/
.sale-box {
	margin-bottom: 10px;
	position: absolute;
	width: em(55px);
	height: em(55px);
	line-height: em(55px);
	background: $bkgMenu;
	border: none;
	border-radius: 50%;
	text-transform: none;
	text-align: center;
	color: #fff;
	@include fontStyle_3(); 
	left: em(10px);
	top: em(15px);
	right: auto;
	z-index: 20;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;

	&:hover {
		background: $bkgMenu;
	}

	@media (max-width: 991px) {
		width: 40px;
		height: 40px;
		line-height: 35px;
		//right: 0 !important;
		//top: 0 !important;
		font-size: 12px;
	}
	@media (max-width: 767px) {
		width: 35px;
		height: 35px;
		//right: 0 !important;
		//top: 0 !important;
		line-height: 35px;
		font-size: 10px;
	}
}

/*TIME COUNTER*/
.clock-block { 

	.clock { 
		text-align: center;
		margin-top: -40px;
	} 
	
	li {
		display: inline-block;
		width: 60px;
		height: 60px;
		background: #373737;   
		margin: 0 15px; 
		text-transform: uppercase;
		color: #fff;
		padding-top: 10px; 

		@media (max-width: 991px) {
			width: 50px;
			height: 50px;
			padding-top: 5px;
			margin: 0 5px;
		}

		span {
			display: block;  
		}
	}
}

/*SCROLL TO TOP BUTTON*/
#scroll-to-top {
	position: fixed;
	right: 10px;
	bottom: 100px;
	width: 40px;
	height: 40px;
	z-index: 400;
	font-size: 0;
	//text-align: center;
	padding-top: 10px;
	color: #eee; 
	background: #000;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s; 
	cursor: pointer;
	padding-left: 15px;
	&:before {
		content: "\f106";
		font-family: "FontAweSome";		
		font-size: 17.5px;
	}

	&:hover {
		background: $bkgMenu;
		color: #fff;
	}
}

/*OWL SLIDER CONTROL*/
.slideNav {
	width: 30px;	
	height: 30px;
	border: 1px solid #ddd;
	margin-right: 0!important;
	margin-left: 6px!important;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: #ddd;
	font-size: 0;
	background: #fff;

	&:hover {
		background: $bkgMenu;
		border-color: $bkgMenu;	
		color: #fff;
	}

	&:before {
		font-family: "FontAweSome";
		text-align: center;
		font-size: 17.5px;	
		line-height: 28px;
		display: block;	
		color: inherit;
		width: 100%;
		height: 100%; 
		text-align: center;
		padding: 0;
	}	
}
.slidePrev {
	&:before {
		content: "\f104";				
	}	
}
.slideNext {
	&:before {
		content: "\f105";
	}	
}
.owl-controls {

	.owl-nav {

		.owl-prev, .owl-next {
			@extend .slideNav;	
		}

		.owl-prev {
			@extend .slidePrev;
		}

		.owl-next {
			@extend .slideNext;
		}	
	}
}

/*BLOCK TAG*/
.tags_block {

	a {
		text-transform: uppercase;
		font-size: 11px;	
		border: 1px solid #ededed;
		padding: 0 18px;
		float: left;
		margin-right: 10px;
		margin-bottom: 12px;
		min-height: 30px;
		padding-top: 5px; 

		&:hover {
			background: $bkgMenuHover;
			color: #fff;
		}
	}
}

/*BUTTON COMPARE SUBMIT ON CATEGORY PAGE*/
.button.button-medium.bt_compare {
	
	-webkit-box-shadow: none;
	box-shadow: none;
	opacity: 1;
	border: none;

	span {
		border: none;
	}
}

/*REVIEW STAR*/
.reviews-container {
	min-height: 16px;
	margin-bottom: 14px; 
}
.comments_note {
	font-size: 0;
	text-align: center;

	.nb-comments {
		display: none;
	}
}
.star_content {
	line-height: 1;

	.star {
		float: none;
		display: inline-block; 
		margin: 0 2px;

		&.star_on {
			display: inline-block;
		}

		&:after {
			color: $colorText2!important; 
		}
	}
}

/*PAGE HEADING*/
.page-heading {
	@include fontStyle_6();
	line-height: 1.2;
	border: none;
	margin-bottom: 30px !important;
	color: #000;
	position: relative;
	.lighter {
		color: $bkgMenu;
		font-weight: normal;
	}
	&:after{
		height: 2px;
		width: 50px;
		background: $bkgMenu;
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

/*PAGE SUB-HEADING*/
.page-subheading {
	@include fontStyle_7();
	font-weight: normal;
	border: none;
	margin-bottom: 0;
	color: $colorHeading;
}

/*HISTORY PRICE*/
.history_price {
	.price {
		color: $bkgMenu;
		font-size: 16px;
		font-weight: bold;	
	}
	
}
.product_list.grid .product-container,
.product_list.list .product-container {
	&:hover {
		
		.product-image-container {
			.product_img_link:before {
				@media (min-width: 768px) {
					//@include hover-screen-show();						
				}
			}
		}
	}
	.product-image-container {
		.product_img_link:before {
			//@include hover-screen();	
		}
		.quick-view-wrapper-mobile {
			display: none;
		}
		.quick-view {			
		}
	}
}

/*==========================================TAB MOBILE==================================================*/
.type-tab {
	@media (max-width: 767px) {
		overflow: visible !important;
		position: relative !important;
		.tab-toogle--mobile {
			width: 47px;
			height: 47px;
			background: $bkgMenuHover;
			line-height: 47px;
			text-align: center;
			cursor: pointer;
			position: absolute;
			right: 0px;
			top: -47px;
			&:before {
				content: "\f205";
				display: block;
				font-family: "FontAweSome";
				font-size: 14px;
				color: $bkgMenuHover;		
				color: #fff;
			}
			&.off {
				&:before{
					content: "\f204";
				}
			}

			+ .nav-tabs {
				@media (max-width: 767px) {
					position: absolute !important;
					display: none;
					//top: 0 !important;
					right: 0;
					width: 50%;
					z-index: 300;
					background: $bkgMenuHover !important;
					padding-top: 0 !important;

					li {
						display: block;
						width: 100%;
						text-align: right;
						a {
							color: #fff !important;
							padding: 10px 15px !important;
							font-family: $fontHeading;
							font-size: 12px !important;
						}
						&.active, &:hover {
							background: $bkgMenu;	
							a {
								background: transparent;
								font-weight: normal !important;
							}
						}
					}
				}
				@media (max-width: 320px) {
					width: 100%;
				}	
			}
		}
	}
}
.bc-filterproduct {
	.tab-toogle--mobile {
		top: 80px;
		right: 80px;
		background: none;
		width: 30px;
		height: 30px;
		line-height: 30px;
		&:before {
			color: $bkgMenuHover;
		}
	}	
}
.bc-bestsellerwithcategory {
	.type-tab{
		.tab-toogle--mobile {
			@media (max-width: 767px) {
				right: -1px;
				+ .nav-tabs {
					right: -1px;

				}	
			}
			
		}
	}
}
/*======================================================================================================*/

.bc-filter-product:not(.tab-pane) .product-container,
.product_list.grid .product-container
 {
	background: #fff;
	padding: 0;
	position: relative;	
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	z-index: auto;
	text-align: center;
	margin: 0 15px 50px;
	@media (min-width: 768px) {
		&:hover {
			z-index: 200;
			.product-image-container {			
				.product_img_link:before {
					//@include hover-screen-show();		
				}			
			}
			
			.quick-view {
				
			}
		}	
	}
	.product-image-container {
		border: none;
		padding: 10px;
		margin-bottom: 0;
		position: relative;	
		text-align: center;
		.product_img_link{
			display: block;
			position: relative;
			overflow: hidden;
			transition: 0.4s;
			img{
				transition: 0.4s;
			}
			
		}
		.product_img_link:before { 
		 	//@include hover-screen();	
		}
		.quick-view-wrapper-mobile {
			display: none;
		}
		.quick-view {			
		}
	}
	.right-block {
		padding: 30px 0 0;
		text-align: center;
		position: relative;		
		width: 100%;
		z-index: 101;
		background: #fff;

		.product-desc, 
		.availability, 
		.lnk_view, 
		.product-flags,
		.price-percent-reduction {
			display: none;
		}

		h5 {
			margin: 0;
			font-family: $fontText;
		}

		.product-name {
			padding: 0 0 3px;
			margin-bottom: 0;
			-ms-text-overflow: ellipsis; 
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: block;
			text-align: center;			
		}

		.content_price {
			margin: 15px 0;
		}

		.button-container {
			/*@media (min-width: 992px) {
				height: 0;
				margin-bottom: 0;
				overflow: hidden;
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;			
			}*/
		}
	}
	&:hover{
				img{
					//transform: scale(1.1,1.1);
				}
			}
}

/*TOP BANNER*/
#header {
	#banner_block_popup {
		margin-bottom: 0;
		text-align: center;
		padding: 10px 0;
		position: relative;
		.text_content {
			color: #fff;
			font-weight: bold;
			@include fontStyle_3();
			text-transform: uppercase;
			padding: 0 30px;
			padding-right: 70px;
			p {
				margin-bottom: 0;
			}
		}
		.ads-bar-link {
			display: inline-block;
			color: $bkgMenu;
			text-decoration: underline;
		}
		.close {
			font-size: 0;
			position: absolute;
			border: none;
			position: absolute;
			right: 0;
			top: 4px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			background: url('../images/close.png') no-repeat center;
			span {
				padding: 0;
				border: none;
				display: none;
			}
		}
	}
	
}

/*COOKIE LAW*/
#cookie-popup {
	background: rgba(0,0,0,0.8); 
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	#cookie_block_popup {
		margin-bottom: 0;
		padding: 20px 0 10px;
		@media(max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.text_content {
		width: 60%;
		float: left;
		@include fontStyle_3();
		color: #fff;
		@media (max-width: 370px) {
			width: 100%;
		}
	}
	.button-container {
		width: 40%;
		float: right;
		text-align: right;
		padding-top: 5px;
		@media (max-width: 370px) {
			width: 100%;
			text-align: left;
		}
	}
	.btn {
		@extend .btn-style-1;		
		padding: 0 15px;
		margin: 0 5px 10px;
		border: none;
		text-shadow: none;
		cursor: pointer;
		span {
			background: none;
			border: none;
			padding: 0;
			font-size: 12px;
		}
	}
}
.content-manufacture-block{
	@media (min-width: 767px) {
		padding:0 15px;
	}
}

/*STICKY MENU*/
@media (min-width: 991px) {
.menu-sticky {
	&.bc-stickymenu-fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 100;
		background: $colortheme2;
		box-shadow: 0 1px 5px rgba(0,0,0,0.20);
	}	
}
}

/*TOOLTIP*/
.tooltip-inner {
	border-radius: 0;
	font-family: $fontText;
}

/*PRELOADER ZOOM*/
#fancybox-loading,.zoomPreload,.product_list > p {
	@include preload();
	background: none;
	font-size: 0;
	opacity: 1;
	border: none;
	> div{
		background: none;
	}
}
.product_list{
	position: relative;
	> p{
	position: absolute;
	left: 50%;
	top: -50px;
	img{
		width: 0;
		font-size: 0;
	}
}
}
.block .list-block li a:hover{
	color: $colortheme1;
	&:before{
		color: $colortheme1;
	}
}
.img-responsive{
	display: inline-block;
}
.ajax_block_product .product-container {
	.left-block{
		position: relative;
		.button-container{
			position: absolute;
			right: 10px;
			bottom: 10px;
			.quick-view, .wishlist, .compare{
				@include fade-button();
				position: relative;
				float: none;				
			    margin-top: 10px;

			}
			.quick-view{
				animation-delay: 0.2s;
			}
			.wishlist{
				animation-delay: 0.3s;
			}
			.compare{
				animation-delay: 0.4s;
			}
		}
	}	
	&:hover{
		.left-block .button-container{
			.quick-view, .wishlist, .compare{
				display: block;
			}
		}
	}
}		
@media (max-width: 767px){
	#order-detail-content #cart_summary td.cart_product{
		width: 30%;
	}
	#order-detail-content #cart_summary tbody td.cart_description{
		width: 70%;
	}
	#order-detail-content #cart_summary td{
		padding: 15px 30px 15px 15px;
	}
	#order-detail-content #cart_summary td.cart_unit{
		text-align: left;
	}
	#order-detail-content #cart_summary td:before{
		margin-bottom: 5px;
	}
}
